<template>
  <NuxtLoadingIndicator style="position: sticky;" />
  <slot />
</template>

<script lang="ts" setup>
useHead({
  bodyAttrs: {
    class: 'funnel'
  }
})
</script>
